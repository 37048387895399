@import '../../theme/styles/mixins.scss';

.formContainer {
  margin-top: 32px;
}

.groupOrderNameLabel {
  color: var(--colors-text-body-0);
  font-size: var(--heading-font-size-h3) !important;
  line-height: 19px;
  margin-bottom: 16px;
}

.groupSizeInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.groupSizeInput {
  max-width: 160px;
}

.footer {
  @include mobile {
    position: sticky;
    bottom: 0;
    padding-top: 16px;
    margin-top: 0;
    background: var(--colors-surfaces-primary-0);
  }
}
