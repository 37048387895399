@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.content {
  @include mobile {
    padding: 16px 16px 32px;
  }
}

.hostDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--colors-surfaces-primary-0);
  height: fit-content;
  border-radius: 12px;

  @include mobile {
    box-shadow: 0px 11px 30px 0px getBoxShadowRGBA(0.15);
    padding: 16px 16px 32px;
  }
}

.hostEmailField {
  margin-top: 16px;

  label {
    font-weight: var(--font-heading-weight);
    font-size: 1.25rem;
  }

  input {
    height: 44px;
    text-overflow: ellipsis;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid var(--colors-borders-secondary);
    margin-top: 6px;

    &:read-only {
      background-color: var(--colors-surfaces-components-input-0);
    }
  }
}

.footer {
  background-color: var(--colors-surfaces-primary-0);

  @include mobile {
    box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
    padding: 16px 16px 32px;
    position: sticky;
    width: 100%;
    bottom: 0;
    margin-top: 0;
  }
}
