@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.content {
  @include mobile {
    padding: 16px 16px 32px;
  }
}

.sharedLinkDetails {
  display: flex;
  flex-direction: column;
  background-color: var(--colors-surfaces-primary-0);
  height: fit-content;
  border-radius: 12px;

  @include mobile {
    box-shadow: 0px 11px 30px 0px getBoxShadowRGBA(0.15);
    padding: 16px 16px 32px;
  }
}

.linkDetailsHeader {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 25px;

  h3 {
    color: var(--colors-text-body-0);
    font-weight: var(--font-heading-weight);
  }

  h2 {
    color: var(--colors-text-body-4);
    line-height: normal;
  }
}

.sharedLinkNote {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--colors-surfaces-secondary-1);
  margin-bottom: 24px;
}

.sharedLink {
  margin-bottom: 16px;

  input {
    height: 44px;
    text-overflow: ellipsis;
    padding: 10px;
    padding-left: 32px;
    border-radius: 6px;
    border: 1px solid var(--colors-borders-secondary);
  }

  div {
    padding-left: 10px;
  }

  svg {
    width: 16px;
    height: 16px;
    color: var(--colors-text-body-0);
  }
}

.copyButton {
  padding: 8px;
  height: 32px;
  min-width: 80px;
  background-color: var(--colors-surfaces-primary-4);

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  span {
    font-weight: var(--font-heading-weight);
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 30px;
  background-color: var(--colors-surfaces-primary-0);

  @include mobile {
    box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
    padding: 16px 16px 32px;
    position: sticky;
    width: 100%;
    bottom: 0;
    margin-top: 0;
  }

  button {
    height: 42px;
    width: 100%;

    &:last-child {
      background-color: var(--colors-surfaces-primary-4);

      &:hover,
      &:focus {
        background-color: var(--colors-surfaces-primary-2);
      }
    }
  }
}
