@import '../../../theme/styles/mixins.scss';

.container {
  border-radius: 16px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 20px 0px getBoxShadowRGBA();
  }

  &.selected {
    border: 2px solid var(--colors-borders-primary);
  }
}

.outletInfo {
  padding: 16px;
}
