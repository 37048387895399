@import '../../../theme/styles/mixins';

.boxPickupSections {
  h2 {
    margin-top: 34px;
    margin-bottom: 27px;
    padding-bottom: 5px;
    border-bottom: 0.5px solid var(--colors-borders-secondary);
  }
  .sectionInfoText {
    margin-bottom: 30px;
  }
  .orderSectionRow {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 15px;

    button {
      height: 70px;
      min-width: 110px;
      max-width: 160px;
      margin-right: 16px;
      border-radius: 8px;
      background-color: var(--colors-surfaces-primary-2);
      border: none;

      p {
        min-width: 100px;
        @include textEllipsis();
      }
    }
  }
  .selected {
    background-color: var(--colors-highlights-1);
    border: 3px solid var(--colors-highlights-0) !important;
  }
  .skeleton {
    height: 70px;
    width: 110px;
    border-radius: 8px;
    margin-right: 16px;
  }
}