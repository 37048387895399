.row {
  flex-direction: row;
  display: flex;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    fill: var(--colors-text-body-0);
  }
}

.container {
  margin-bottom: 40px;
}
