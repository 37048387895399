.container {
  width: 100%;
}

.mapView {
  height: 160px;
}

.metaContainer {
  padding: 20px;
}

.outletName {
  width: 70%;
  height: 30px;
  border-radius: 20px;
}

.orderFormats {
  margin-top: 8px;
  display: flex;
  flex-direction: row;

  > div {
    margin-left: 20px;
  }

  :first-child {
    margin-left: 0px;
  }
}

.orderFormat {
  height: 22px;
  width: 90px;
  border-radius: 10px;
}

.businessInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 15px;

  .businessNumber,
  .legalName {
    height: 22px;
    border-radius: 20px;
  }

  .businessNumber {
    width: 60%;
  }

  .legalName {
    width: 65%;
  }
}

.bottomContainer {
  padding: 15px 20px 20px 20px;
}

.infoItemContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  :first-child {
    width: 40%;
    height: 22px;
    border-radius: 20px;

    &.w30 {
      width: 30%;
    }

    &.w40 {
      width: 40%;
    }

    &.w50 {
      width: 50%;
    }

    &.w60 {
      width: 60%;
    }
  }

  :last-child {
    margin-top: 8px;
    width: 20%;
    height: 20px;
    border-radius: 20px;

    &.w50 {
      width: 40%;
    }
  }
}
