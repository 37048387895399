@import '../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 8px;

  p {
    font-weight: var(--font-heading-weight);
    text-align: center;
  }

  button {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    gap: 8px;

    &::after {
      border-radius: 8px;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      font-weight: var(--font-heading-weight);
      color: var(--colors-button-primary-content);
    }

    @include mobile {
      border-radius: 100px;

      &::after {
        border-radius: 100px;
      }
    }
  }
}
