@import '../../../theme/styles/mixins';

.detailsBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  height: 100%;
  max-height: calc(var(--vh100) * 0.85 - 220px);

  @include smallMobile {
    max-height: calc(var(--vh100) * 0.85 - 350px);
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .left {
    flex-basis: 40%;
  }

  .right {
    flex-basis: 60%;
  }

  @include smallMobile {
    flex-direction: column;

    .left,
    .right {
      flex-basis: 100%;
    }
  }
}

.footer {
  padding-top: 24px;
  position: sticky;
  bottom: 0;

  @include smallMobile {
    flex-direction: column-reverse;
  }
}

.footerActions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  .footerButton {
    width: 100%;
    height: 42px;
    border-radius: 100px;
  }
}

.iconDataField {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.orderSummary {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldIcon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  align-self: center;
}

.fieldContent {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .fieldLabel {
    border-radius: 4px;
    height: 10px;
    width: 100px;
  }

  .fieldValue {
    border-radius: 5px;
    height: 18px;
    width: 150px;
  }
}
