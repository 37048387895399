@import '../../../theme/styles/mixins';

.modal {
  > div {
    overflow: hidden;
    padding: 0px;
  }
}

.modalHeader {
  z-index: 1;
  top: 10px;
  right: 10px;
  position: absolute;

  button {
    width: 36px;
    height: 36px;
    padding: 10px;
    box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
  }
}

.container {
  max-width: 100%;
  max-height: var(--vh100);
  overflow: auto;
  width: 480px;
  background: var(--colors-surfaces-primary-0);

  @include mobile {
    width: 100%;
  }
}

.topContainer {
  display: flex;
  flex-direction: column;
}

.bottomContainer {
  padding: 8px 20px 20px 20px;
  overflow-x: hidden;
}
