.container {
  display: flex;
  flex-direction: column;
}

.informationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  svg {
    width: 20px;
    height: 20px;
  }

  > :first-child {
    fill: var(--colors-text-body-1);
    margin-right: 8px;
  }

  > :last-child {
    fill: var(--colors-text-body-2);
  }
}

.infoSubTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 28px;
  margin-top: -8px;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.infoTitle {
  font-size: 16px;
  font-weight: 400;
}

.infoSubTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
