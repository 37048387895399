.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 30px;

  p.proceedNote {
    font-weight: var(--font-heading-weight);
    text-align: center;
    line-height: normal;
  }
}

.proceedButton {
  width: 100%;
  height: 42px;
  gap: 8px;

  svg {
    height: 15px;
    width: 15px;
  }
}
