@import '../../../theme/styles/mixins.scss';

.bannerContainer {
  position: relative;
  height: 170px;

  @include mobile {
    height: 130px;
  }
}

.bannerOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);

  &.outletClosed {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.logoContainer {
  position: absolute;
  left: 16px;
  bottom: 22px;
  display: flex;
  flex-direction: row;
}

.logoImage {
  border-radius: 8px;
  margin-right: 8px;
}
