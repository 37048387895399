@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    @include textEllipsis(1);
    margin: 0px !important;
  }
}

.cuisineText {
  color: var(--colors-text-body-1);
  height: 22px;
  @include textEllipsis(1);
}

.addressText {
  margin-top: 8px;
  margin-bottom: 4px;
  @include textEllipsis(1);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  align-items: flex-end;
  flex: 1;

  p {
    color: var(--colors-text-body-1);
    margin-bottom: 5px;
  }
}

.footerLeft {
  display: flex;
  flex-direction: row;
}

.availableOrderType {
  svg {
    fill: var(--colors-text-body-0);
  }
}
