@import '../../../theme/styles/mixins.scss';

.header {
  height: 20px;
  width: 150px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.orderItemList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.orderItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderItemRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.orderItemQty {
  width: 24px;
  height: 24px;
  border-radius: 50px;
}

.orderItemName {
  width: 150px;
  height: 18px;
  border-radius: 5px;
}

.orderItemPrice {
  width: 60px;
  height: 15px;
  border-radius: 4px;
}
