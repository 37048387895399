.caret {
  fill: var(--colors-text-body-2);
}

.headerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;

  svg {
    padding-top: 2px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: var(--colors-text-body-1);
  }
}

.outletStatusContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.outletStatus {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &.outletStatusOpened {
    color: var(--colors-text-success);
  }

  &.outletStateClosed {
    color: var(--colors-text-danger);
  }
}

.outletOpeningHour {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.openingHoursContainer {
  display: flex;
  flex-direction: column;
  padding: 24px 0px 24px 24px;
}

.openingHoursGroups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 8px;

  > button {
    min-height: 32px;
    margin-right: 8px;
    margin-top: 5px;
    border-radius: 40px;
  }

  :last-child {
    margin-right: 0px;
  }
}

.selectedOpeningHourOrderFormat {
  background-color: var(--colors-highlights-1);
  border-radius: 40px;
  border: 3px solid var(--colors-highlights-0);
}
