.placeholderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  svg {
    height: 48px;
    width: 48px;
    fill: var(--colors-surfaces-primary-1);
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    max-width: 220px;
  }

  .selectRestaurantButton {
    margin-top: 10px;
    min-width: 160px;
  }
}
