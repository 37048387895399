@import '../../../theme/styles/mixins.scss';

.header {
  display: flex;
  gap: 10px;

  .left.grow {
    flex-grow: 1;
  }

  .right {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.outletName {
  line-height: 36px;

  @include textEllipsis();
}

.row {
  display: flex;
  flex-direction: row;
}

.orderFormats {
  gap: 8px;
  padding: 10px 0;
  margin: 22px 0;
  flex-wrap: wrap;

  > button {
    height: 42px;

    &:disabled {
      cursor: not-allowed;
    }

    > div {
      display: flex;
    }

    p {
      white-space: nowrap;
      font-weight: var(--font-heading-weight);
      padding-right: 5px;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    fill: var(--colors-text-body-3);
  }
}

.infoButton {
  height: 32px;
  padding: 8px 12px;
  background-color: var(--colors-surfaces-primary-4);

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }
}

.statusWrapper {
  margin-top: 8px;
  margin-bottom: 8px !important;
  background-color: var(--colors-components-outletStatus-background) !important;
  padding: 8px;
  border-radius: 8px;
  p {
    color: var(--colors-components-outletStatus-text-0) !important;
  }
  svg {
    fill: var(--colors-components-outletStatus-text-0) !important;
  }
}

.contactInfoRows {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .contactInfoRow {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      flex-grow: 1;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--colors-text-body-2);
  }
}
