.header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  gap: 8px;
}

.headerWithClose {
  flex-direction: row;
  justify-content: space-between;
}

.headerWithBack {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
}

.actionButton {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--colors-button-secondary-background-default);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-text-heading-1);
  }
}
