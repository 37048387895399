.modal > div {
  max-height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  gap: 5px;
  justify-content: space-between;
}

.headerText {
  font-weight: 600;
}

.closeButton {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 36px;
  align-self: baseline;

  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-button-secondary-content);
  }
}
