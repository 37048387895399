.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.searchInput {
  height: 35px;
  margin-bottom: 16px;

  div[class*='leftAccessory'] {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  div[class*='rightAccessory'] {
    svg {
      height: 14px;
      width: 14px;
    }
  }

  input {
    height: 35px;
    padding: 6px 40px;
    border-radius: 58px;
    border: 1px solid var(--colors-surfaces-primary-1);
  }
}

.attendeeList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.emptyAttendees {
  border: none;
  padding-top: 30px;

  h2 {
    margin-top: 0;
  }

  svg {
    display: none;
  }

  h2 {
    font-size: 16px;
  }
}
