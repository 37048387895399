@import '../../theme/styles/mixins.scss';

.container {
  display: none;
  flex-direction: column;
  flex: 1;

  @include mobile {
    display: flex;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.headerTabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  top: 72px;
  z-index: 2;
  box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
}

.headerTabButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  border-radius: 0;
  padding: 0;
  border-bottom: 2.5px var(--colors-surfaces-primary-0) solid;
  background-color: var(--colors-surfaces-primary-0) !important;

  &::after {
    border-radius: 0;
  }

  &.selected {
    border-color: var(--colors-highlights-2);
  }
}

.content {
  margin-top: 50px;
  padding: 16px 16px 32px;
}

.footer {
  padding: 16px 16px 32px;
  background-color: var(--colors-surfaces-primary-0);
  box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
  width: 100%;
  position: sticky;
  bottom: 0;
  align-self: flex-end;
}
