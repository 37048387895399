@import '../../theme/styles/mixins.scss';

.wrapper {
  flex-direction: row;
  display: flex;
  padding: 32px;

  @include mobile {
    padding: 16px;
  }
}

.rightContainer {
  @include mobile {
    display: none;
  }
}

.skeletonContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mobile {
    padding: 16px;
  }
}

.newOrderTitle {
  height: 24px;
  margin-bottom: 24px;
  width: 100px;
  border-radius: 10px;
}

.newOrderCard {
  min-width: 460px;
  margin-left: 24px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  cursor: unset !important;

  @include tablet {
    width: 400px;
    min-width: unset;
  }
}