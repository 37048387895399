@import '../../../theme/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.emptyItemList {
  border: none;
  padding-top: 30px;

  svg {
    height: 75px;
    width: 75px;

    @include mobile {
      height: 65px;
      width: 65px;
    }
  }

  h2 {
    font-size: 16px;
  }
}
