@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 16px;
  background-color: var(--colors-surfaces-primary-0);
  height: fit-content;
  border-radius: 12px;
  border: 2px solid var(--colors-borders-primary);
  box-shadow: 0px 11px 30px 0px getBoxShadowRGBA(0.15);
}
