.itemListContainer {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.itemContainer {
  padding: 0;
  cursor: default !important;

  div > p {
    font-size: var(--body-font-size);
  }

  .itemQuantity {
    height: 24px;
    width: 24px;
    max-width: 24px;
    border-radius: 50px;
    background-color: var(--colors-components-badges-default-background);
    margin-right: 12px;
    text-align: center;

    p {
      font-size: 12px;
      line-height: 24px;
      color: var(--colors-components-badges-default-text);
      align-self: center;
    }
  }
}
