@import '../../theme/styles/mixins.scss';

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @include xDesktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }

  &.wl {
    grid-template-columns: repeat(2, 1fr);

    @include xDesktop {
      grid-template-columns: repeat(1, 1fr);
    }

    @include xxxDesktop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.groupHeaderSkeleton {
  width: 150px;
  border-radius: 12px;
  height: 22px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.searchInputSkeleton {
  margin-bottom: 24px;
  width: calc((100% - 64px)/4);
  height: 46px;
  border-radius: 58px;
  margin-top: 40px;

  @include xxxDesktop {
    width: calc((100% - 42px)/3);
  }

  @include xDesktop {
    width: calc((100% - 42px)/3);
  }

  @include tablet {
    width: calc((100% - 20px)/2);
  }

  @include mobile {
    width:100%
  }
}