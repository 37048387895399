.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dataField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.editButton {
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background-color: var(--colors-surfaces-primary-4);
  border: none !important;
  padding: 4px;

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  svg {
    position: absolute;
    fill: var(--colors-text-body-0);
    width: 16px;
    height: 16px;
  }
}
