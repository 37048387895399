@import '../../../theme/styles/mixins.scss';

.modal > div {
  display: flex;
  flex-direction: column;
  width: 500px;

  @include mobile {
    width: 100%;
  }

  h2 {
    line-height: var(--heading-line-height-h3);
  }

  .header {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.wrapper {
  @include mobile {
    max-width: 100%;
  }

  .infoText {
    margin-right: 30px;
  }
}

.orderTimeSwitch {
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  background-color: var(--colors-surfaces-primary-4);
  padding: 5px;
  border-radius: 60px;
  width: fit-content;

  button::after {
    transition: none;
  }

  button:not(.active):active::after {
    filter: none;
  }

  &.whiteLabeled {
    button:not(.active)::after {
      background: none;
    }

    &.heatApp {
      button.active {
        &:hover::after,
        &:focus::after {
          background-color: var(--colors-button-secondary-background-default);
          filter: brightness(90%);
        }
      }
    }
  }
}

.dateTimeSection {
  border-top: 0.5px solid var(--colors-borders-secondary);
  border-bottom: 0.5px solid var(--colors-borders-secondary);
  padding: 6px 0;
  margin-top: 30px;
}

.continueButton {
  margin-top: 32px;
  width: 100%;
}
