.header {
  display: inline-flex;
  flex: 1;
  margin-bottom: 32px;
  justify-content: space-between;
  gap: 16px;

  h2 {
    line-height: normal;
  }
}

.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.headerActions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.closeButton {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--colors-button-secondary-background-default);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 36px;
  align-self: baseline;

  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-button-secondary-content);
  }
}

.copyButton {
  height: 36px;
  min-width: 90px;
  background-color: var(--colors-surfaces-primary-4);

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  span {
    font-weight: var(--font-heading-weight);
  }
}
