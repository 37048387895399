.caret {
  height: 32px;
  margin: 0 8px;
  fill: var(--colors-text-body-0);
  align-self: center;
}

.headerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
}

.calendar {
  margin-top: 15px;
}
