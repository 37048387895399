@import '../../../theme/styles/mixins.scss';

.container {
  border-radius: 16px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  min-height: 150px;
  display: flex;
  flex-direction: column;
}

.topContainer {
  position: relative;
  height: 170px;

  @include mobile {
    height: 130px;
  }
}

.logoPlaceholder {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  bottom: 22px;
  left: 16px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin: 16px 16px 0px 16px;
}

.outletName {
  height: 28px;
  width: 90%;
  border-radius: 154px;
}

.outletAddress {
  height: 22px;
  width: 40%;
  margin-top: 8px;
  border-radius: 154px;
}

.footerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 16px 16px 16px;
  align-items: flex-end;
  flex: 1;
}

.leftFooterContainer {
  width: 50%;
  display: flex;
  flex-direction: row;

  :first-child {
    margin-right: 10px;
  }
}

.footerMeta {
  height: 22px;
  width: 25%;
  border-radius: 154px;
}
