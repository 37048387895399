@import '../../../theme/styles/mixins.scss';

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subHeader {
  span {
    color: var(--colors-text-body-0);
  }
}

.headerButton {
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background-color: var(--colors-surfaces-primary-4);
  border: none !important;
  padding: 4px;

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  svg {
    position: absolute;
    fill: var(--colors-text-body-0);
  }
}

.headerBackButton {
  margin-right: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.headerCloseButton {
  svg {
    width: 18px;
    height: 18px;
  }
}

.headerMoreActionsButton {
  height: 32px;
  width: 32px;
  padding: 8px;
  background-color: var(--colors-surfaces-primary-4);
  border: none !important;

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  > svg {
    width: 16px;
    height: 16px;
  }
}

.headerActionsPopup {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 120px;

  > div {
    padding: 8px 15px;
    font-weight: var(--font-body-weight);
  }
}
