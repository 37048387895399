@import '../../../theme/styles/mixins';

.listContainer {
  overflow-y: auto;
  padding: 10px;
}

.listFooter {
  display: flex;
  margin-top: 32px;
  justify-content: center;

  button {
    min-width: 150px;
    background-color: transparent;
  }
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .marginTop {
    margin-top: 16px;
  }
}

.noRestaurantsPlaceholder {
  flex-grow: 0;
  padding: 35px 10px 10px;
  justify-content: flex-start;

  svg {
    height: 90px;
    width: 90px;
  }

  h2 {
    font-size: var(--heading-font-size-h3);
    line-height: var(--heading-line-height-h3);
  }
}

.skeletonCard {
  box-shadow: 0px 0px 10px getBoxShadowRGBA();
}

.outletsSectionHeader {
  margin-top: 20px;
  margin-bottom: 15px;
}
