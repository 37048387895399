@import '../../../theme/styles/mixins.scss';

.container {
  flex: 3;
  padding: 16px 8px;
  background-color: var(--colors-surfaces-primary-0);
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
  height: fit-content;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.header {
  @extend .row;

  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 32px;

  h3 {
    font-weight: var(--font-heading-weight);
  }
}

.pastOrdersButton {
  height: 32px;
  padding: 8px 12px;
  background-color: var(--colors-surfaces-primary-4);

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }

  &::after {
    background-color: var(--colors-surfaces-primary-4);

    &:hover,
    &:focus {
      background-color: var(--colors-surfaces-primary-2);
    }
  }
}

.orderList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 30px;
  overflow-y: auto;
  max-height: 400px;

  @include hideScrollBar;
}

.emptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 30px;
  margin-bottom: 30px;

  p {
    text-align: center;
    max-width: 220px;
  }

  svg {
    width: 48px;
    height: 48px;
    fill: var(--colors-surfaces-primary-1);
    margin-bottom: 16px;
  }
}

.footerSection {
  display: flex;
  padding-top: 10px;
  justify-content: center;
  position: relative;
}

.loadMoreButton {
  min-width: 150px;
  height: 42px;
  background-color: transparent;
}

.footerSection::after {
  content: '';
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    var(--colors-surfaces-primary-0)
  );
}
