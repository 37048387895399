.selectButton {
  width: 100%;
  height: 66px;
  border-radius: 8px;
  background-color: var(--colors-surfaces-primary-4);
  border: none;

  &::after {
    border-radius: 8px;
    background-color: var(--colors-surfaces-primary-4);
    transition: none;
  }

  &:not(.selected) {
    &:hover::after,
    &:focus::after {
      background-color: var(--colors-button-secondary-background-hover-1);
      border-color: var(--colors-button-secondary-background-hover-1);
    }
  }

  p {
    font-size: 14px;
  }

  svg {
    height: 16px;
    width: 16px;
    fill: var(--colors-text-body-2);
  }

  &.selected {
    border: 2px solid var(--colors-borders-tertiary);
  }

  &.selected,
  &.selected::after {
    background-color: var(--colors-highlights-3) !important;
  }

  &.heatApp {
    &:hover {
      svg {
        fill: var(--colors-text-heading-1);
      }
    }

    &.selected,
    &.selected::after {
      background-color: var(--colors-surfaces-primary-4) !important;
    }

    &:hover::after,
    &.selected:hover,
    &.selected:hover::after {
      background-color: var(--colors-highlights-0) !important;
    }
  }
}
