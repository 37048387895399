@import '../../../theme/styles/mixins.scss';

.headerText {
  width: 250px;
  height: 36px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.row {
  flex-direction: row;
  display: flex;

  .icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    border-radius: 5px;
  }

  .text {
    width: 300px;
    height: 24px;
    border-radius: 12px;
    margin-bottom: 10px;
    
    @include mobile {
      width: 100%;
    }
  }
}