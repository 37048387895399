@import '../../../theme/styles/mixins';

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100px;

  @include mobile {
    width: 100%;
  }
}

.modalFooter {
  padding-top: 20px;
  display: flex;
  position: absolute;
  width: calc(100% - 20px);
  margin-left: -10px;
  bottom: 0;
  padding-bottom: 30px;
  background-color: var(--colors-surfaces-primary-0);
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > div:first-child {
    margin-bottom: 20px;
    justify-content: center;
  }

  button {
    flex: 1;
    margin: 0 10px;
  }

  &.noPadding {
    padding-bottom: 0;
  }
}

.dayRow {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 10px;

  button {
    height: 70px;
    min-width: 110px;
    margin-right: 16px;
    border-radius: 8px;
    padding: 8px;
    border: none;

    &::after {
      border-radius: 5px;
      background-color: var(--colors-surfaces-primary-2);
    }
  }

  span {
    margin-top: 5px;
    font-size: var(--heading-font-size-h3);
  }
}

.selected {
  background-color: var(--colors-highlights-1);
  border: 3px solid var(--colors-highlights-0) !important;
}

.pickerTimeSection {
  margin-top: 5px;
  overflow-y: scroll;
  min-height: 200px;
  max-height: 300px;
  margin-bottom: 120px;
}

.pickerTimeRow:first-child {
  border-top: 1px solid var(--colors-surfaces-primary-1);
}

.pickerTimeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 16px;
  border-bottom: 1px solid var(--colors-surfaces-primary-1);
  cursor: pointer;

  .selectable {
    svg {
      height: 15px;
      width: 15px;
      fill: var(--colors-button-secondary-content);
    }
  }

  span {
    color: var(--colors-text-heading-0);
  }
}

.pickerTime {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.capitalize {
  text-transform: capitalize;
}
