@import '../../../theme/styles/mixins';

.modalContent {
  width: 720px;
  padding: 16px 16px 32px;
  align-items: center;

  @include mobile {
    width: 100%;
  }
}

.modalBody {
  display: flex;
  flex-direction: row;
  gap: 4px;
  max-height: calc(var(--vh100) * 0.8);

  @include hideScrollBar;

  @include mobile {
    flex-direction: column;
  }
}
