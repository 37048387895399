.container {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  h3 {
    text-transform: capitalize;
  }
}

.orderList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.emptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-text-body-8);
  }
}
