.column {
  display: flex;
  flex-direction: column;
}

.container {
  @extend .column;
  gap: 10px;
}

.upcomingOrder {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px;
}

.orderInfo {
  @extend .column;
  gap: 6px;
}

.orderIcon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.orderDateTime {
  height: 14px;
  border-radius: 5px;
  margin-bottom: 6px;
}

.orderName {
  height: 18px;
  border-radius: 5px;
}

.attendeeCount {
  height: 12px;
  border-radius: 4px;
}

.width100 {
  width: 100px;
}

.width120 {
  width: 120px;
}

.width140 {
  width: 140px;
}

.width160 {
  width: 160px;
}

.width180 {
  width: 180px;
}

.width200 {
  width: 200px;
}

.width220 {
  width: 220px;
}

.width240 {
  width: 240px;
}
