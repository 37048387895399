.container {
  display: flex;
  flex-direction: column;
}

.openingHoursRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 8px;
}

.openingDayCell {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .day {
    text-transform: capitalize;
  }
}

.openingPeriodCell {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.closedDay {
  color: var(--colors-text-danger);
}

.isToday {
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-heading-weight);
}

.exceptionDay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
