@import '../../../theme/styles/mixins';

.modalContent {
  width: 700px;

  @include mobile {
    width: 100%;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  height: 100%;
  max-height: calc(var(--vh100) * 0.85 - 220px);

  @include smallMobile {
    max-height: calc(var(--vh100) * 0.85 - 350px);
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .left {
    flex-basis: 40%;
  }

  .right {
    flex-basis: 60%;
  }

  @include smallMobile {
    flex-direction: column;

    .left,
    .right {
      flex-basis: 100%;
    }
  }
}

.orderTotal {
  margin-bottom: 0;

  > div:nth-child(2) {
    padding-bottom: 0;
  }
}

.modalFooter {
  padding-top: 24px;
  position: sticky;
  bottom: 0;
  background-color: var(--colors-surfaces-primary-0);

  @include smallMobile {
    flex-direction: column-reverse;
  }
}

.modalFooterActions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  button {
    width: 100%;

    &.secondary {
      background-color: var(--colors-surfaces-primary-4);
      &:hover,
      &:focus {
        background-color: var(--colors-surfaces-primary-2);
      }
      &::after {
        background-color: var(--colors-surfaces-primary-4);
      }
    }

    &.viewStatusButton {
      gap: 6px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
