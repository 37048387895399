@import '../../theme/styles/mixins.scss';

.container {
  padding: 32px;

  &.overlay {
    background: rgba(0, 0, 0, 0.2);
  }

  @include mobile {
    padding: 20px 15px 30px;
    display: none;
  }
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto auto;
}

.sections {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.orderScheduler {
  display: flex;
  flex-direction: column;
  flex: 3;
  height: fit-content;
  gap: 24px;
  min-width: 240px;
}
