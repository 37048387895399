@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fieldContainer {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.fieldLeftContent {
  display: inline-flex;
  align-items: center;
  min-height: 32px;
}

.highlighted {
  position: absolute;
  left: -8px;
  right: 0;
  background-color: var(--colors-highlights-5);
  border-radius: 8px;
  height: 100%;
  min-height: 40px;
  width: calc(100% + 52px);

  animation: fadeOut 0.3s ease-out 2s forwards;
}

.fieldIcon {
  display: flex;
  align-items: center;

  div {
    padding: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    fill: var(--colors-text-body-8);
    flex-shrink: 0;
  }
}

.fieldData {
  display: flex;
  flex-direction: column;
  gap: 2px;

  span {
    line-height: 24px;
  }
}

.fieldCompletedIndicator {
  width: 20px;
  height: 20px;
  color: var(--colors-text-success);
  align-self: right;
}
