@import '../../theme/styles/mixins';
@import '@ancon/wildcat-ui/shared/colors.scss';

.container {
  display: flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;

  &.summer {
    background-color: map-get($colors, 'gold');

    span {
      color: map-get($colors, 'nero');
    }
  }

  &.standard {
    background-color: map-get($colors, 'dodgerBlue');

    span {
      color: map-get($colors, 'white');
    }
  }

  span {
    font-weight: 500;
  }
}
