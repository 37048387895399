.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.restaurantName {
  width: 200px;
  margin-bottom: 8px;
  height: 22px;
  border-radius: 10px;
}

.restaurantAddress {
  @extend .column;
  gap: 10px;

  > div {
    border-radius: 8px;
    height: 14px;
  }

  .addressLine1 {
    width: 300px;
  }

  .addressLine2 {
    width: 100px;
  }

  .distance {
    width: 150px;
  }
}

.restaurantMap {
  height: 135px;
  border-radius: 10px;
  margin-top: 18px;
  margin-bottom: 6px;
}

.businessInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .businessNumber,
  .legalName {
    height: 14px;
    border-radius: 8px;
  }

  .businessNumber {
    width: 150px;
  }

  .legalName {
    width: 170px;
  }
}

.restaurantMetaDataList {
  @extend .column;
  gap: 14px;
  margin-top: 20px;

  .dataRow {
    display: flex;
    flex-direction: row;

    .dataLogo {
      width: 21px;
      height: 21px;
      border-radius: 6px;
      margin-right: 10px;
    }

    .dataLines {
      @extend .column;
      gap: 8px;

      > div {
        height: 16px;
        border-radius: 8px;
      }
    }

    .dataLine1 {
      width: 250px;
    }

    .dataLine2 {
      width: 100px;
    }
  }
}
