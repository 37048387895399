.calendar {
  display: flex;
  flex-direction: column;
}

.calendarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  button {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: var(--colors-surfaces-primary-4);
    padding: 4px;

    &:hover:not(:disabled) {
      background-color: var(--colors-surfaces-primary-1);
    }

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      position: absolute;
      fill: var(--colors-text-body-7);

      width: 20px;
      height: 20px;
    }
  }
}
