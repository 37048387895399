@import '../../../theme/styles/mixins';

.modalContent {
  width: 720px;
  padding: 20px 10px;
  align-items: center;

  @include mobile {
    width: 100%;
    padding: 16px 8px;
  }
}

.modalHeader {
  padding-left: 10px;
  padding-right: 10px;

  @include mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.modalBody {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 20px;
  overflow-x: hidden;
  max-height: calc(var(--vh100) * 0.8);

  @include mobile {
    flex-direction: column;
    max-height: calc(var(--vh100) * 0.8 - 120px);
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  flex-basis: 48%;

  @include mobile {
    flex-basis: 100%;

    &.hiddenInMobile {
      display: none;
    }
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 52%;

  @include mobile {
    flex-basis: 100%;

    &.hiddenInMobile {
      display: none;
    }
  }
}

.restaurant {
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.rightFooter {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;

  button {
    width: 100%;
  }

  @include mobile {
    display: none;
  }
}

.searchInputContainer {
  margin-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;

  input {
    border-radius: 58px;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    border: 1px solid var(--colors-surfaces-primary-1);
  }

  div[class*='rightAccessory'] {
    padding-right: 25px;
  }

  @include mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.modalFooterMobile {
  display: none;

  .secondary {
    border-color: var(--colors-surfaces-primary-4);
    background-color: var(--colors-surfaces-primary-4);

    &:hover,
    &:focus {
      background-color: var(--colors-surfaces-primary-2);
    }

    &::after {
      background-color: var(--colors-surfaces-primary-4);
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 16px 8px;
    background-color: var(--colors-surfaces-primary-0);
    width: 100%;

    button {
      width: 100%;
    }
  }
}
