.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.inputContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  input {
    height: 44px;
    font-size: 1.4rem;
    border-radius: 6px;
    border: 1px solid var(--colors-borders-secondary);
    text-align: center;
    padding: 15px 10px;
  }

  p {
    width: 25px;
    text-align: center;
  }

  button {
    width: 42px;
    height: 42px;
    padding: 5px;
    flex-shrink: 0;
    background-color: var(--colors-surfaces-primary-4);

    &::after {
      background-color: var(--colors-surfaces-primary-4);
    }

    &:hover,
    &:focus {
      background-color: var(--colors-button-secondary-background-hover-1);
    }

    &:hover::after,
    &:focus::after {
      filter: none !important;
      background-color: var(--colors-button-secondary-background-hover-1);
    }

    svg {
      width: 18px;
      height: 18px;
      fill: var(--colors-text-body-0);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.modifierIcon {
  width: 30px;
  height: 30px;
}

.valid {
  border-color: var(--colors-borders-secondary);
}

.spacer {
  margin-bottom: 18px;
}
