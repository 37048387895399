@import '../../../theme/styles/mixins.scss';

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  .title {
    height: 20px;
    width: 150px;
    border-radius: 6px;
  }

  .searchBox {
    height: 32px;
    width: 100%;
    border-radius: 58px;
    border: 1px solid var(--colors-surfaces-components-skeleton-0);
  }
}

.attendeeList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.attendeeItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px;

  @include mobile {
    padding: 16px;
  }
}

.attendeeDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.attendeeName {
  width: 150px;
  height: 18px;
  border-radius: 5px;
}

.itemsCount {
  width: 80px;
  height: 12px;
  border-radius: 4px;
}

.dropdownIcon {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
