.outletStatusWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  .outletStatusText {
    color: var(--colors-components-outletStatus-text-0);
    font-weight: var(--font-heading-weight);
    margin-bottom: 5px;
  }

  .outletAvailabilityTag {
    padding: 4px;
    border-radius: 4px;
    background-color: var(--colors-highlights-0);
    p {
      font-weight: var(--font-heading-weight);
    }
  }

  .separator {
    margin-left: 5px;
  }

  &.inline {
    flex-direction: row;
    justify-content: flex-start;

    p {
      color: var(--colors-components-outletStatus-text-1);
    }
    .outletAvailabilityTag {
      background-color: unset;
      padding: 0 4px;
    }
    .outletStatusText {
      margin-bottom: 0;
    }
    &.hasContent {
      margin-bottom: 5px;
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      padding-top: 4px;
      fill: var(--colors-components-outletStatus-text-1);
    }
  }
}
