@import '../../../theme/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  padding: 16px;
  background-color: var(--colors-surfaces-primary-0);
  height: fit-content;
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px getBoxShadowRGBA();
  gap: 32px;
}

.calendar {
  width: 100%;
}
