@import '../../../theme/styles/mixins';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.totalContainer {
  margin-top: 28px;

  .subTotalText {
    height: 12px;
    width: 80px;
    border-radius: 4px;
  }

  .subTotalValue {
    height: 12px;
    width: 100px;
    border-radius: 4px;
  }

  .separator {
    height: 1px;
    border-top: 1px solid var(--colors-text-body-2);
    margin-top: 14px;
    margin-bottom: 18px;
  }

  .totalText {
    height: 18px;
    width: 70px;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .vatIncludedText {
    height: 15px;
    width: 110px;
    border-radius: 4px;
  }

  .totalValue {
    height: 28px;
    width: 120px;
    border-radius: 5px;
    align-self: center;
  }
}
