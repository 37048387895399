.bodyText {
  height: 16px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.header {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.outletNameText {
  height: 24px;
  margin-bottom: 10px;
  width: 200px;
  border-radius: 10px;
}

.row {
  flex-direction: row;
  display: flex;
}

.addressText {
  width: 150px;
}

.infoButton {
  height: 32px;
  width: 49px;
  border-radius: 100px;
}

.contactInfoRows {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.orderFormatsWrapper {
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.orderFormat {
  width: 140px;
  height: 42px;
  border-radius: 100px;
}

.contactInfoRow {
  height: 25px;
}

.contactBodyText {
  height: 16px;
  border-radius: 10px;
}
