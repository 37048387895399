@import '../../theme/styles/mixins';

.formContainer {
  display: flex;
  flex-direction: column;
}

.header {
  @include mobile {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--colors-surfaces-primary-0);
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}

.divider {
  height: 1px;
  background-color: var(--colors-surfaces-primary-5);
  margin-left: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.footer {
  @include mobile {
    position: sticky;
    bottom: 0;
    padding-top: 16px;
    margin-top: 0;
    background: var(--colors-surfaces-primary-0);
  }
}
