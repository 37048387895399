.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--colors-text-body-8);
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-4);
  }
}

.orderDetails {
  display: flex;
  flex-direction: column;
}

.serviceTime {
  margin-bottom: 8px;
}

.lowercase {
  text-transform: lowercase;
}
