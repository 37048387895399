@import '../../../theme/styles/mixins.scss';

.restaurantContainer {
  max-width: 100%;
  overflow: hidden;
  background: var(--colors-surfaces-primary-0);

  padding: 0px;

  @include mobile {
    width: 100%;
  }
}

.restaurantHeader {
  margin-bottom: 24px;
}

.businessInfo {
  margin-top: 16px;
  margin-bottom: 24px;
}
