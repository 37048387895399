.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 5px;
  height: 24px;

  span {
    font-weight: var(--font-heading-weight);
  }

  &.pending {
    background-color: var(--colors-text-warning-2);
  }

  &.active {
    background-color: var(--colors-text-success);
  }
}
